import bg from '../assets/bg.png';
import gallery01 from '../assets/08e1a96a9dfd53e0635b66777844f69e.jpg';
import gallery02 from '../assets/welcome.jpg';
import gallery03 from '../assets/Oreo-Cake-Square-2-500x500.webp';
import gallery04 from '../assets/istockphoto-899069970-612x612.jpg';
import knife from '../assets/knife.png';
import menu from '../assets/menu.png';
import overlaybg from '../assets/overlaybg.png';
import spoon from '../assets/spoon.svg';
import welcome from '../assets/welcome.jpg';
import findus from '../assets/findus.png';
import quote from '../assets/quote.png';
import tcv from '../assets/tcv.png';
import t from '../assets/t-removebg-preview.png';
import candle from '../assets/candle.png';
import offer from "../assets/offer.png"
import dogcake from "../assets/dogcake.jpg"
export default {
  bg,
  t,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  quote,
  tcv,
  candle,
  offer,
  dogcake,
};
